import { useState } from 'react';
import { Box, Grid, TextInput, Button } from 'grommet';
import styled from 'styled-components';

const Input = styled(TextInput)`
  text-overflow: ellipsis;
`;

const ClipboardCopy = ({ copyText }) => {
  const [isCopied, setIsCopied] = useState(false);

  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  // onClick handler for copy button
  const handleCopyClick = () => {
    copyTextToClipboard(copyText)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Grid columns={['flex', '75px']} align="center">
      <Box>
        <Input value={copyText} readOnly focusIndicator={false} plain="full" />
      </Box>
      <Box>
        <Button plain onClick={handleCopyClick} alignSelf="center" label={isCopied ? 'Copied' : 'Copy'} style={{ color: 'blue' }} />
      </Box>
    </Grid>
  );
};

export default ClipboardCopy;
