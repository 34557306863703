import { useReducer } from 'react';
import { Box, Text, DateInput, Button, Anchor, DataTable, MaskedInput, TextInput, Form } from 'grommet';
import { FormPrevious, FormNext, FormClose, Copy, AddCircle } from 'grommet-icons';
import { DateTime, Interval } from 'luxon';
import styled from 'styled-components';

const inputStyles = [
  `
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 14px;
  line-height: 14px;
`,
];

const StyledMaskedInput = styled(MaskedInput)(inputStyles);
const StyledTextInput = styled(TextInput)(inputStyles);

const TimeSheet = ({ metadata, data }) => {
  function* days(interval) {
    let cursor = interval.start.startOf('day');
    while (cursor < interval.end) {
      yield cursor;
      cursor = cursor.plus({ days: 1 });
    }
  }

  const dateRangeToColumns = (dateRange) => {
    const columns = [];

    columns.push({
      property: 'project',
      primary: true,
      header: (
        <Box align="center" margin={{ bottom: 'xsmall', left: 'small' }}>
          <Text size="small">Project</Text>
        </Box>
      ),
      render: ({ project }) => (
        <Box direction="row" align="center" pad={{ vertical: 'xsmall', horizontal: 'none' }}>
          <Box margin={{ right: 'small', top: 'xsmall' }}>
            <FormClose size="20px" />
          </Box>
          <Box margin={{ top: 'xsmall' }}>
            <StyledTextInput value={project ? project : undefined} />
          </Box>
        </Box>
      ),
    });

    const interval = Interval.fromISO(dateRange[0] + '/' + dateRange[1]);
    const arr = Array.from(days(interval));
    arr.forEach((day, i) => {
      columns.push({
        property: 'day' + (i + 1),
        size: '50px',
        header: (
          <Box align="center" margin={{ bottom: 'xsmall', left: 'xsmall' }}>
            <Text size="xsmall">{day.weekdayShort}</Text>
            <Text size="small" weight="bold">
              {day.day}
            </Text>
          </Box>
        ),
        render: ({ hours }) => (
          <Box pad="none" margin={{ top: 'xsmall' }}>
            <StyledMaskedInput
              size="small"
              textAlign="center"
              mask={[
                {
                  regexp: /^[0-9]{1,6}(\.\d{1})?$/,
                },
              ]}
              value={hours}
            />
          </Box>
        ),
      });
    });

    return columns;
  };

  const today = DateTime.now();
  const blankRow = (rowId) => {
    return {
      id: rowId,
      project: '',
      day1: 0.0,
      day2: 0.0,
      day3: 0.0,
      day4: 0.0,
      day5: 0.0,
    };
  };

  const initialState = {
    rowCounter: 0,
    dateRange: [today.startOf('week').toISO(), today.startOf('week').plus({ days: 5 }).toISO()],
    tableData: [blankRow(0)],
  };

  function reducer(state, action) {
    switch (action.type) {
      case 'addRow':
        return { ...state, tableData: [...state.tableData, blankRow(state.rowCounter + 1)], rowCounter: state.rowCounter + 1 };
      case 'deleteRow':
        return { ...state, tableData: state.tableData.filter((row) => row.id !== action.id) };
      case 'setDateRange':
        const currentDate = DateTime.fromISO(state.dateRange[0]);

        switch (action.setDateType) {
          case 'current':
            return { ...state, dateRange: [today.startOf('week').toISO(), today.startOf('week').plus({ days: 5 }).toISO()] };
          case 'nextWeek':
            return { ...state, dateRange: [currentDate.plus({ days: 7 }).toISO(), currentDate.plus({ days: 12 }).toISO()] };
          case 'prevWeek':
            return { ...state, dateRange: [currentDate.minus({ days: 7 }).toISO(), currentDate.minus({ days: 2 }).toISO()] };
          default:
            const newDate = DateTime.fromISO(action.dateRange[0]);
            return { ...state, dateRange: [newDate.startOf('week').toISO(), newDate.startOf('week').plus({ days: 5 }).toISO()] };
        }
      default:
        return { ...state };
    }
  }

  const [tableState, dispatchTableState] = useReducer(reducer, initialState);
  const displayDate = DateTime.fromISO(tableState.dateRange[0]);

  return (
    (data && (
      <Box>
        <Box direction="row" width="medium" justify="between" align="center" fill="horizontal" pad={{ horizontal: 'small', top: 'small' }}>
          <DateInput
            value={tableState.dateRange}
            buttonProps={{
              label: `${DateTime.fromISO(tableState.dateRange[0]).toLocaleString({ locale: 'en-gb' })} - ${DateTime.fromISO(tableState.dateRange[1]).toLocaleString({
                locale: 'en-gb',
              })}`,
              size: 'small',
            }}
            calendarProps={{
              margin: 'small',
              alignSelf: 'center',
              daysOfWeek: true,
              firstDayOfWeek: 1,
              size: 'small',
              header: ({ date: currentDate, locale, onPreviousMonth, onNextMonth, previousInBound, nextInBound }) => (
                <Box direction="row" align="center" justify="between">
                  <Button disabled={!previousInBound} onClick={onPreviousMonth}>
                    <Box>
                      <FormPrevious />
                    </Box>
                  </Button>
                  <Text size="small">
                    <strong>
                      {currentDate.toLocaleDateString(locale, {
                        month: 'long',
                        year: 'numeric',
                      })}
                    </strong>
                  </Text>
                  <Button disabled={!nextInBound} onClick={onNextMonth}>
                    <Box>
                      <FormNext />
                    </Box>
                  </Button>
                </Box>
              ),
            }}
            onChange={(newDateRange) => dispatchTableState({ type: 'setDateRange', dateRange: newDateRange.value })}
          />
          <Box width="1px" fill="vertical" background="background-back">
            &nbsp;
          </Box>
          <Box direction="row">
            <Button size="small" label="This week" onClick={() => dispatchTableState({ type: 'setDateRange', setDateType: 'current' })} />
            <Button size="small" icon={<FormPrevious />} onClick={() => dispatchTableState({ type: 'setDateRange', setDateType: 'prevWeek' })} />
            <Button size="small" icon={<FormNext />} onClick={() => dispatchTableState({ type: 'setDateRange', setDateType: 'nextWeek' })} />
          </Box>
        </Box>
        <Box direction="row" justify="between" align="start" margin={{ top: 'medium' }} pad="small" background="background-contrast">
          <Box>
            <Text size="large">
              {displayDate.monthShort} {displayDate.year}
            </Text>
            <Text size="small" color="text-weak">
              Week {displayDate.weekNumber}
            </Text>
          </Box>
        </Box>
        <Box margin={{ top: 'medium' }} pad={{ horizontal: 'small' }}>
          <DataTable
            columns={dateRangeToColumns(tableState.dateRange)}
            data={tableState.tableData}
            onClickRow={(event) => {
              if (event.target.nodeName == 'svg' || event.target.nodeName == 'path') dispatchTableState({ type: 'deleteRow', id: event.datum.id });
            }}
            step={10}
            size="small"
            pad={{ horizontal: 'xsmall', vertical: 'hair' }}
            fill="horizontal"
          />
        </Box>
        <Box direction="row" margin={{ top: 'small' }} justify="between" align="center" pad={{ horizontal: 'small' }}>
          <Button size="small" icon={<AddCircle />} label="Add new row" gap="xsmall" onClick={() => dispatchTableState({ type: 'addRow' })} />
          <Button primary label="Save" onClick={() => {}} />
        </Box>
        <Box direction="row" margin={{ top: 'medium', left: 'xsmall' }} justify="between" align="center" pad={{ horizontal: 'small', bottom: 'small' }}>
          <Anchor style={{ textDecoration: 'none' }}>
            <Box direction="row" align="center">
              <Copy size="20px" />
              <Text margin={{ left: 'xsmall' }} size="xsmall">
                Copy last week's timesheet
              </Text>
            </Box>
          </Anchor>
        </Box>
      </Box>
    )) ||
    null
  );
};

export default TimeSheet;
