import { Anchor, Avatar, Box, Button, Grid, Layer, Text } from 'grommet';
import { Sync } from 'grommet-icons';
import { DateTime } from 'luxon';
import { useState } from 'react';
import styled from 'styled-components';

import EventDetail from './event-detail';

const HostAvatar = styled(Avatar)`
  box-shadow: 0 0 0 1px #fff, 0 0 0 2.5px var(--at-link);
`;

const Event = ({ data }) => {
  const [open, setOpen] = useState();
  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(undefined);

  return (
    <Box align="center" justify="center" height={{ min: 'auto' }}>
      <Grid columns={['xsmall', '2px', 'flex']} fill="horizontal" gap={{ row: 'none', column: 'small' }}>
        <Box align="end" justify="start" direction="column" pad={{ right: 'xsmall' }}>
          <Text size="small" weight="bold" color="text-weak" textAlign="end">
            {data.when}
          </Text>
        </Box>
        <Box align="center" justify="start" width="xxsmall" background={{ color: 'background-back' }} direction="column" overflow="visible" pad={{ top: '4px' }}>
          <Box align="center" justify="center" position="absolute" round="full" width="8px" height="8px" background={{ color: 'blue' }} style={{ maxWidth: 'unset' }} />
        </Box>
        <Box align="start" justify="center" pad={{ left: 'small' }}>
          {!data.isAllDay && data.startDate && (
            <Text size="small" weight="bold" color="blue" margin={{ bottom: 'xsmall' }}>
              {DateTime.fromISO(data.startDate).toLocaleString(DateTime.TIME_SIMPLE, { locale: 'en-US' })} &ndash;{' '}
              {DateTime.fromISO(data.endDate).toLocaleString(DateTime.TIME_SIMPLE, { locale: 'en-US' })}
            </Text>
          )}
          {data.isAllDay && (
            <Text size="small" weight="bold" color="blue" margin={{ bottom: 'xsmall' }}>
              All Day
            </Text>
          )}
          <Box style={{ display: 'block' }}>
            <Text size="medium" weight="bold" style={{ verticalAlign: data.isRecurring ? 'middle' : 'baseline' }}>
              {data.title}
            </Text>
            {data.isRecurring && (
              <Box style={{ display: 'inline-flex' }} direction="row" align="stretch" margin={{ left: 'small' }}>
                <Sync size="small" color="text-weak" />
                <Text margin={{ left: 'xsmall' }} size="xsmall" color="text-weak">
                  {' '}
                  Series
                </Text>
              </Box>
            )}
          </Box>
          <Box align="start" justify="start" direction="row" pad={{ top: 'small', bottom: 'medium' }}>
            <Box align="center">
              <HostAvatar src={data.organizer.avatar} size="30px" />
              <Text size="xsmall" margin={{ top: 'xsmall' }}>
                Host
              </Text>
            </Box>
            <Box direction="row" align="center">
              {Array.isArray(data.attendees) &&
                data.attendees.length > 0 &&
                data.attendees.map((attendee, index) => {
                  if (index > 3) return null;
                  return <Avatar key={index} src={attendee.avatar} size="30px" margin={{ left: 'small' }} />;
                })}
              {Array.isArray(data.attendees) && data.attendees.length > 4 && (
                <Text size="xsmall" color="dark-3" margin={{ left: 'xsmall' }}>
                  + {data.attendees.length - 4} more
                </Text>
              )}
            </Box>
          </Box>
          <Box align="center" justify="center" direction="row" margin={{ bottom: 'large' }}>
            {data.onlineMeetingUrl && <Button label="Join Meeting" href={data.onlineMeetingUrl} target="_blank" margin={{ right: 'small' }} size="small" color="green" primary />}
            <Anchor onClick={onOpen}>
              <Text size="xsmall">Details</Text>
            </Anchor>
          </Box>
        </Box>
      </Grid>
      {open && (
        <Layer id="Event detail overlay" position="center" onClickOutside={onClose} onEsc={onClose}>
          <EventDetail data={data} closeHandler={onClose} />
        </Layer>
      )}
    </Box>
  );
};

export default Event;
