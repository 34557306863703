import { Box, Grid } from 'grommet';
import { DateTime, Duration } from 'luxon';
import { memo, useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';

const ClockLocation = styled.div`
  font-size: 20px;
  font-weight: 400;
  color: #1b1c1d;
`;

const ClockInfo = styled.div`
  font-size: 12px;
  font-weight: 300;
  color: #606162;
`;

const Clock = styled.div`
  font-size: 24px;
  font-weight: 300;
  color: #1b1c1d;
`;

const ClockTime = memo(({ timezone, label }) => {
  const [currentTime, setCurrentTime] = useState(DateTime.now());
  const prevTime = useRef();

  useEffect(() => {
    prevTime.current = currentTime;

    const tick = setInterval(() => {
      const now = DateTime.now();
      if (now.get('minute') != prevTime.current.get('minute')) {
        setCurrentTime(now);
      }
    }, 1000);
    return () => clearInterval(tick);
  });

  console.log('rendering clocktime');

  const capitalize = (s) => (s && s[0].toUpperCase() + s.slice(1)) || '';

  function isNegativeDuration(duration) {
    return duration.valueOf() < 0;
  }

  function formatDuration(duration, pattern) {
    if (isNegativeDuration(duration)) {
      return `-${duration.negate().toFormat(pattern)}`;
    } else {
      return `+${duration.toFormat(pattern)}`;
    }
  }

  const zoneTime = currentTime.setZone(timezone);
  const displayTime = zoneTime.setLocale('en-US').toLocaleString(DateTime.TIME_SIMPLE);

  const offset = Duration.fromObject({ minutes: zoneTime.offset - currentTime.offset }); // '5h, -3h30m'
  const relativeDate = capitalize(currentTime.plus({ minutes: zoneTime.offset - currentTime.offset }).toRelativeCalendar()); // 'Today', 'tomorrow', 'yesterday'

  let displayOffset = formatDuration(Duration.fromObject({ minutes: zoneTime.offset - currentTime.offset }), 'h:mm');
  if (offset.get('minute') % 60 == 0) displayOffset = displayOffset.slice(0, -3); // Trim padding 00m for on-the-hour offsets

  return (
    <Box border={{ color: 'light-4' }} round="xxsmall" height={{ min: 'auto' }} pad={{ vertical: 'small', horizontal: 'medium' }}>
      <Grid columns={['flex', 'auto']} align="center">
        <Box>
          <ClockInfo>
            {relativeDate}, {displayOffset}HRS
          </ClockInfo>
          <ClockLocation>{label}</ClockLocation>
        </Box>
        <Box>
          <Clock>{displayTime}</Clock>
        </Box>
      </Grid>
    </Box>
  );
});

const WorldClock = ({ metadata, data }) => {
  const [locations, setLocations] = useState(data?.locations || []);

  useEffect(() => {
    // Update the current time value every second
    if (!data.locations) return;

    setLocations(data.locations);
  }, [data]);

  const deleteItem = (list, index) => {
    return list.splice(index, 1)[0];
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return;

    const tempList = locations;
    const removed = deleteItem(tempList, source.index);

    tempList.splice(destination.index, 0, removed);
    setLocations(tempList);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {(locations.length && (
        <Droppable droppableId="world-clock-droppable">
          {(provided, snapshot) => (
            <div id="world-clock-draggable-list" key="world-clock-droppable" {...provided.droppableProps} ref={provided.innerRef}>
              <Grid gap="small">
                {/* Iterate over all defined locations to render one Clock widget per location */}
                {locations.map((location, index) => (
                  <Draggable key={location.label} draggableId={`${location.label}-${index}`} index={index}>
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <ClockTime timezone={location.timezone} label={location.label}></ClockTime>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Grid>
            </div>
          )}
        </Droppable>
      )) ||
        null}
    </DragDropContext>
  );
};

export default WorldClock;
