import { Accordion, AccordionPanel, Avatar, Box, Button, Card, CardBody, CardHeader, Text } from 'grommet';
import { FormClose, Sync } from 'grommet-icons';
import { DateTime } from 'luxon';
import { useState } from 'react';
import styled from 'styled-components';

import ClipboardCopy from '../../components/utils/clipboard-copy';

const HostAvatar = styled(Avatar)`
  box-shadow: 0 0 0 1px #fff, 0 0 0 2.5px var(--at-link);
`;

const Attendee = ({ data, host }) => {
  return (
    <Box direction="row" align="center" margin={{ bottom: 'small' }}>
      {host && <HostAvatar src={data.avatar} size="30px" />}
      {!host && <Avatar src={data.avatar} size="30px" />}
      <Box margin={{ left: 'small' }}>
        <Text size="small" weight="bold">
          {data.name}
        </Text>
        <Text size="xsmall" color="text-weak" style={{ textTransform: 'capitalize' }}>
          {host && 'Host'}
          {data.response && data.response}
          {!host && !data.response && 'Waiting for response'}
        </Text>
      </Box>
    </Box>
  );
};

const EventDetail = ({ data, closeHandler }) => {
  const [activeIndex, setActiveIndex] = useState([0]);

  return (
    <Card round={false} width="medium" border={{ color: 'border', size: 'xsmall', style: 'solid', side: 'all' }}>
      <CardHeader pad={{ right: 'xsmall', top: 'xsmall' }} background="background-back" align="stretch">
        <Box pad="medium">
          <Text size="xlarge" style={{ fontWeight: '200' }}>
            {data.title}
          </Text>
        </Box>
        <Button alignSelf="start" onClick={closeHandler}>
          <FormClose />
        </Button>
      </CardHeader>
      <CardBody>
        <Box margin={{ bottom: 'medium' }}>
          <Text color="blue" weight="bold" size="small" margin={{ bottom: 'small' }}>
            {data.meetingType && `${data.meetingType} meeting`}
          </Text>
          <Box direction="row" align="flex-end" margin={{ bottom: 'xsmall' }}>
            <Text size="xsmall">{DateTime.now().toLocaleString({ weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' })}</Text>
            {data.isRecurring && (
              <Box style={{ display: 'inline-flex' }} direction="row" align="center" margin={{ left: 'small' }}>
                <Sync size="14px" color="text-weak" />
                <Text margin={{ left: 'xsmall' }} size="xsmall" color="text-weak">
                  Series
                </Text>
              </Box>
            )}
          </Box>
          {!data.isAllDay && data.startDate && (
            <Text size="xsmall" margin={{ bottom: 'small' }}>
              {DateTime.fromISO(data.startDate).toLocaleString(DateTime.TIME_SIMPLE)} &ndash; {DateTime.fromISO(data.endDate).toLocaleString(DateTime.TIME_SIMPLE)}
            </Text>
          )}
          {data.isAllDay && (
            <Text size="xsmall" margin={{ bottom: 'small' }}>
              All Day
            </Text>
          )}
          <Text color="text-weak" size="xsmall">
            {data.meetingType}
          </Text>
        </Box>
        <Accordion multiple activeIndex={activeIndex} onActive={(newActiveIndex) => setActiveIndex(newActiveIndex)}>
          {data.onlineMeetingUrl && (
            <AccordionPanel
              label={
                <Text margin={{ vertical: 'small' }} size="xsmall">
                  Meeting link
                </Text>
              }
            >
              <Box pad={{ vertical: 'small', right: 'small' }} margin={{ bottom: 'small' }}>
                <ClipboardCopy copyText={data.onlineMeetingUrl} />
              </Box>
            </AccordionPanel>
          )}
          <AccordionPanel
            label={
              <Text margin={{ vertical: 'small' }} size="xsmall">
                Meeting description
              </Text>
            }
          >
            <Box pad={{ vertical: 'small', right: 'small' }} margin={{ bottom: 'small' }}>
              <Text size="small">{data.description ? data.description : 'No meeting description available'}</Text>
            </Box>
          </AccordionPanel>
          <AccordionPanel
            label={
              <Text margin={{ vertical: 'small' }} size="xsmall">
                People ({data.attendees?.length ? data.attendees?.length + 1 : 1})
              </Text>
            }
          >
            <Box pad={{ vertical: 'small', right: 'small' }} margin={{ bottom: 'small' }}>
              <Attendee host={true} data={data.organizer} />
              {data.attendees?.map((attendee, i) => {
                return <Attendee key={i} data={attendee} />;
              })}
            </Box>
          </AccordionPanel>
        </Accordion>
      </CardBody>
    </Card>
  );
};

export default EventDetail;
